<template>
  <div class="container justify-content-space-between">
    <div class="w-300">
      <!--<div class="position-fixed w-300 b-20 t-80 hide-scrollbar bdr-10" style="overflow-y: scroll">-->
      <div style="overflow-y: auto"
           :class="divDeskTop?'w-300 bdr-10 position-fixed h-700 t-60 bg-f z-index-888':'w-300 bdr-10 h-700'">
        <div v-if="mainDeck" class="bg-f bdr-10 pd-10">
          <div class="justify-content-space-between align-items-center fs-16 mg-t-10">
            <div class="text-strong">记忆库</div>
            <div><img src="/static/image/side_bar_add_folder.png" style="cursor: pointer" class="mg-r-10 w-20"
                      @click="onAddDeckClick(mainDeck)"></div>
          </div>
          <div v-if="mainDeck && mainDeck.decks">
            <deck-item v-on:on-options-click="onOptionsClick" v-on:on-deck-click="onDeckClick" class="mg-t-20"
                       :decks="{全部:mainDeck}" :activedid="activeDid"></deck-item>
          </div>
          <div class="mg-t-20 c-8 fs-12" v-else>
            暂无子记忆库
          </div>
        </div>
      </div>
    </div>
    <div v-if="deck" class="pd-b-100 w-685">
      <div class="bg-f bdr-10 pd-12">
        <div v-if="advanceDate" class="pd-10 row-reverse">
          <a @click="onAdvanceDateClick">{{ advanceDate }}</a>
        </div>
        <div class="display-flex">
          <div class="flex-1 fs-24">
            <template>{{ deck.simpleName }}</template>
          </div>
          <div class="w-75 mg-t-4">
            <div class="align-items-center">
              <el-dropdown :show-timeout="0">
              <span class="el-dropdown-link">
                <img src="/static/image/content_set.png" class="w-28">
              </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="onSwitchStudyClick">{{ deck.enableStudy ? '停止学习' : '恢复学习' }}
                  </el-dropdown-item>
                  <el-dropdown-item @click.native="onRestartStudyClick">重设学习进度</el-dropdown-item>
                  <el-dropdown-item @click.native="onSettingStudyClick">学习设置</el-dropdown-item>
                  <!--<el-dropdown-item>导出</el-dropdown-item>-->
                </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown :show-timeout="0" class="mg-l-15">
              <span class="el-dropdown-link">
                <!--<i class="el-icon-more fs-24 "></i>-->
                <img src="/static/image/content_more.png" class="w-28">
              </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="onEditDeckClick(deck)">编辑</el-dropdown-item>
                  <el-dropdown-item @click.native="onDeleteDeckClick(deck)">删除</el-dropdown-item>
                  <el-dropdown-item @click.native="onCheckDeckDataClick(deck)">检查数据</el-dropdown-item>
                  <!--<el-dropdown-item>导出</el-dropdown-item>-->
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
        <div class="mg-t-10 c-8 fs-12">
          <span>学习中</span> <span class="c-3">{{ deck.allLearningCard - deck.handledCard }}</span>
          <span class="mg-l-20">已掌握</span> <span class="c-1">{{ deck.handledCard }}</span>
          <span class="mg-l-20">未学习</span> <span class="c-1">{{ deck.newCard[1] }}</span>
          <span class="mg-l-20">疑难</span> <span class="c-1">{{ deck.hardCard }}</span>
        </div>
        <div class="fs-12" v-if="deck.totalCard == 0 || deck.newCard[1] > 0">
          <el-progress class="mg-t-15" :show-text="false" :stroke-width="6"
                       :percentage="deck.totalCard>0?parseInt((deck.totalCard - deck.newCard[1]) / deck.totalCard * 100):0"></el-progress>
          <div class="mg-t-10 justify-content-space-between c-9">
            <div v-if="deck.totalCard > 0">已学 {{
                parseInt((deck.totalCard - deck.newCard[1]) / deck.totalCard *
                    100)
              }}%
            </div>
            <div v-else>已学 0%</div>
            <div>{{ deck.totalCard - deck.newCard[1] }} / {{ deck.totalCard }}</div>
          </div>
        </div>
        <div class="handled" v-else>
          <el-progress class="mg-t-15" :show-text="false" :stroke-width="8"
                       :percentage="parseInt(deck.handledCard / deck.totalCard * 100)"></el-progress>
          <div class="fs-12 mg-t-10 justify-content-space-between c-9">
            <div>已掌握 {{ parseInt(deck.handledCard / deck.totalCard * 100) }}%
            </div>
            <div>{{ deck.handledCard }} / {{ deck.totalCard }}</div>
          </div>
        </div>
        <div class="justify-content-space-between align-items-center mg-t-40">
          <div class="align-items-center bdr-10 fs-12">
            <div class="text-align-center">
              <div class="text-strong fs-24">{{ deck.newCard[0] }}</div>
              <div class="c-9">今日新卡</div>
            </div>
            <div class="text-align-center mg-l-80">
              <div class="text-strong fs-24">{{
                  parseInt(deck.reviewCard[0]) + parseInt(deck.learningCard) +
                  parseInt(deck.relearningCard)
                }}
              </div>
              <div class="c-9">待复习</div>
            </div>
            <div class="text-align-center mg-l-80">
              <div class="text-strong fs-24">
                {{ Math.ceil((deck.newCard[0] + deck.reviewCard[0] + deck.learningCard) / 6) }}
              </div>
              <div class="c-9">预计耗时(分)</div>
            </div>
          </div>
          <div>
            <template v-if="mainDeck.needBuy">
              <div class="row-reverse">
                <el-button @click="onBuyCardClick" type="primary" class="bdr-25 fs-12 w-100">前往购买</el-button>
              </div>
              <div class="c-9 fs-12 mg-t-10">已购买请删除，重新下载导入</div>
            </template>
            <template v-else-if="deck.enableStudy ">
              <el-button
                  v-if="deck.newCard[0] > 0 || parseInt(deck.reviewCard[0]) + parseInt(deck.learningCard) > 0"
                  @click="onBeginStudyClick" type="primary" class="bdr-25 fs-12">开始记忆
              </el-button>
              <el-button type="default" :disabled="true" class="bdr-25 fs-12 bg-f3f c-9" v-else>
                开始学习
              </el-button>
            </template>
            <el-button disabled="disabled" v-else type="danger" class="bdr-25 fs-12">已停止学习</el-button>
          </div>
        </div>
      </div>

      <div class="bg-f bdr-10 pd-12 mg-t-15">
        <div id="div_sel"
             :class="divSelTop?'border-1 bdc-f3 bdr-10 pd-12 position-fixed t-60 w-635 bg-f z-index-888':''">
          <div class="fs-16 align-items-center justify-content-space-between">
            <div class="text-strong align-items-center">
              我的卡牌
              <img @click="onAddCardClick(deck)" src="/static/image/navbar_add_hover.png" class="w-18 mg-l-15"
                   style="cursor: pointer">
            </div>
            <el-input size="small" v-model="searchForm.keyword" class="w-250 bdr-20 bg-f3f mg-l-15" placeholder="搜索"
                      @keyup.enter.native="onSearchCard">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
          <div class="justify-content-space-between mg-t-15 align-items-center">
            <div class="tabs">
              <div class="mg-r-20 mg-t-5 active display-inline-block">
                <el-dropdown @command="onSelectTypeClick">
                  <span class="c-2">
                    <template v-if="searchForm.type == 'all'">全部</template>
                    <template v-if="searchForm.type == 'lrn'">学习中</template>
                    <template v-if="searchForm.type == 'new'">未学习</template>
                    <template v-if="searchForm.type == 'handled'">已掌握</template>
                    <template v-if="searchForm.type == 'hard'">近期难点</template>
                    <template v-if="searchForm.type == 'hard2'">疑难知识</template>
                    <template v-if="searchForm.type == 'suspend'">已暂停</template>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="all">全部</el-dropdown-item>
                    <el-dropdown-item command="lrn">学习中</el-dropdown-item>
                    <el-dropdown-item command="new">未学习</el-dropdown-item>
                    <el-dropdown-item command="handled">已掌握</el-dropdown-item>
                    <el-dropdown-item command="hard">近期难点</el-dropdown-item>
                    <el-dropdown-item command="hard2">疑难知识</el-dropdown-item>
                    <el-dropdown-item command="suspend">已暂停</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div class="mg-r-20 mg-t-5 active display-inline-block">
                <el-dropdown @command="colorChange">
                  <span class="c-2">
                    <template v-if="searchForm.flag == 0">全部颜色</template>
                    <template v-if="searchForm.flag == 1">无色</template>
                    <template v-if="searchForm.flag == 2">红色</template>
                    <template v-if="searchForm.flag == 3">黄色</template>
                    <template v-if="searchForm.flag == 4">绿色</template>
                    <template v-if="searchForm.flag == 5">蓝色</template>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="0">全部颜色</el-dropdown-item>
                    <el-dropdown-item command="1">无色</el-dropdown-item>
                    <el-dropdown-item command="2">红色</el-dropdown-item>
                    <el-dropdown-item command="3">黄色</el-dropdown-item>
                    <el-dropdown-item command="4">绿色</el-dropdown-item>
                    <el-dropdown-item command="5">蓝色</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div class="mg-r-20 mg-t-5 active display-inline-block">
                <el-dropdown @command="onSelectSortTypeClick">
                  <span class="c-2">
                    <template v-if="searchForm.sort_type == 'id'">按创建时间排序</template>
                    <template v-if="searchForm.sort_type == 'sfld'">按名称排序</template>
                    <template v-if="searchForm.sort_type == 'lapses'">按忘记次数排序</template>
                    <template v-if="searchForm.sort_type == 'reps'">按复习次数排序</template>
                    <template v-if="searchForm.sort_type == 'due'">按到期排序时间</template>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="id">按创建时间排序</el-dropdown-item>
                    <el-dropdown-item command="sfld">按名称排序</el-dropdown-item>
                    <el-dropdown-item command="lapses">按忘记次数排序</el-dropdown-item>
                    <el-dropdown-item command="reps">按复习次数排序</el-dropdown-item>
                    <el-dropdown-item command="due">按到期时间</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div class="mg-t-5 display-inline-block">
                <el-popover
                    placement="top"
                    width="400"
                    trigger="hover"
                    v-model="showFilter"
                >
                  <div>
                    <div class="text-align-center">
                      <div class="justify-content-space-between pd-t-10 pd-b-10"
                           style="border-bottom: 1px solid #f3f3f3;">
                        <div class="lh-36" style="width: 14%">创建时间</div>
                        <el-slider style="width: 65%" class="mg-r-10 mg-l-10"
                                   v-model="searchForm.create_time" :show-tooltip="false" :max="365"></el-slider>
                        <div class="lh-36" style="width: 15%">
                          <span v-if="searchForm.create_time==365">不限</span>
                          <span v-else>近{{ searchForm.create_time }}天</span>
                        </div>
                      </div>
                      <div class="justify-content-space-between pd-t-10 pd-b-10"
                           style="border-bottom: 1px solid #f3f3f3;">
                        <div class="lh-36" style="width: 14%">答题时间</div>
                        <el-slider style="width: 65%" class="mg-r-10 mg-l-10"
                                   v-model="searchForm.answer_time" :show-tooltip="false" :max="30"></el-slider>
                        <div class="lh-36" style="width: 15%">
                          <span v-if="searchForm.answer_time==30">不限</span>
                          <span v-else>近{{ searchForm.answer_time }}天</span>
                        </div>
                      </div>
                      <div class="justify-content-space-between pd-t-10 pd-b-10"
                           style="border-bottom: 1px solid #f3f3f3;">
                        <div class="lh-36" style="width: 14%">下次复习</div>
                        <el-slider style="width: 65%" class="mg-r-10 mg-l-10"
                                   range
                                   :show-tooltip="false"
                                   v-model="searchForm.next_reps_time" :max="100"></el-slider>
                        <div class="lh-36" style="width: 15%">
                        <span v-if="searchForm.next_reps_time[1]==100">
                          {{ searchForm.next_reps_time[0] }}-不限
                        </span>
                          <span v-else>
                          {{ searchForm.next_reps_time[0] }}-{{ searchForm.next_reps_time[1] }}天
                        </span>
                        </div>
                      </div>
                      <div class="justify-content-space-between pd-t-10 pd-b-10"
                           style="border-bottom: 1px solid #f3f3f3;">
                        <div class="lh-36" style="width: 14%">复习次数</div>
                        <el-slider style="width: 65%" class="mg-r-10 mg-l-10"
                                   range
                                   :show-tooltip="false"
                                   v-model="searchForm.reps" :max="20"></el-slider>
                        <div class="lh-36" style="width: 15%">
                        <span v-if="searchForm.reps[1]==20">
                          {{ searchForm.reps[0] }}-不限
                        </span>
                          <span v-else>
                          {{ searchForm.reps[0] }}-{{ searchForm.reps[1] }}次
                        </span>
                        </div>
                      </div>
                      <div class="justify-content-space-between pd-t-10 pd-b-10">
                        <div class="lh-36" style="width: 14%">忘记次数</div>
                        <el-slider style="width: 65%" class="mg-r-10 mg-l-10"
                                   range
                                   :show-tooltip="false"
                                   v-model="searchForm.lapses" :max="20"></el-slider>
                        <div class="lh-36" style="width: 15%">
                        <span v-if="searchForm.lapses[1]==20">
                          {{ searchForm.lapses[0] }}-不限
                        </span>
                          <span v-else>
                          {{ searchForm.lapses[0] }}-{{ searchForm.lapses[1] }}次
                        </span>
                        </div>
                      </div>
                      <div class="justify-content-space-between pd-t-10 pd-b-10">
                        <el-button style="width: 30%" size="medium" @click="onResetFilterClick" class="bdr-8 fs-12">重置
                        </el-button>
                        <el-button style="width: 65%" type="primary" @click="onFilterClick"
                                   size="medium" class="bdr-8 fs-12 mg-r-5">
                          筛选
                        </el-button>
                      </div>
                    </div>

                  </div>
                  <!--                <el-button type="primary" size="mini" class="bdr-8 fs-12" slot="reference">
                                    更多筛选
                                  </el-button>-->
                  <div class="active" slot="reference">更多筛选</div>
                </el-popover>
              </div>
            </div>
            <div>
              <el-button @click="onCustomStudyClick" type="warning" class="bdr-25 fs-12 mg-l-10">
                刷题练习
              </el-button>
              <el-popover
                  class="mg-l-5"
                  placement="right"
                  width="180"
                  trigger="hover"
              >
                <div class="mg-b-5">
                  刷题练习是更灵活的学习方式，会将筛选结果卡片进行队列学习，刷题操作不会参与遗忘曲线记忆算法不会影响卡片下次复习时间
                </div>
                <i class="el-icon-question c-b fs-16 cursor-pointer" slot="reference"></i>
              </el-popover>
            </div>
          </div>
          <div class="justify-content-space-between mg-t-15 c-9">
            <div><span class="c-8 ">筛选出{{ cardListCount }}张卡牌</span></div>
            <div class="align-items-center">
              <div class="mg-r-20">
                <el-checkbox class="c-9" @change="changeShowImg" v-model="showImg">显示图片</el-checkbox>
              </div>
              <div class="mg-r-20">
                <el-checkbox class="c-9" @change="changeShowContent" v-model="showContent">显示内容</el-checkbox>
              </div>
              <div class="cursor-pointer" @click="onSortClick">
                <template v-if="searchForm.sort == 1"><i class="el-icon-sort-down"></i>升序</template>
                <template v-else><i class="el-icon-sort-up"></i>降序</template>
              </div>
              <div @click="onBatchEditClick" class="cursor-pointer mg-l-20">
                {{ batchEditing ? '取消操作' : '批量操作' }}
              </div>
            </div>
          </div>
          <div v-if="batchEditing" class="justify-content-space-between align-items-center mg-t-10 c-9">
            <div>
              <el-checkbox class="c-9" style="font-weight: normal" v-model="batchAllIsChecked"
                           @change="onSelectAllChange">已选 {{
                  batchAllIsChecked ?
                      cardListCount - withoutBatchOptionIds.length : batchOptionIds.length
                }}
              </el-checkbox>
            </div>
            <div class="align-items-center c-app-green">
              <div @click="onBatchDeleteClick" class="cursor-pointer mg-r-20 pd-t-5 pd-b-5">删除</div>
              <div @click="onBatchSuspendClick(-1)" v-if="searchForm.type != 'suspend'"
                   class="cursor-pointer mg-r-20 pd-t-5 pd-b-5">暂停
              </div>
              <div @click="onBatchSuspendClick(1)" v-else class="cursor-pointer mg-r-20 pd-t-5 pd-b-5">取消暂停</div>
              <div @click="onBatchMoveClick" class="cursor-pointer pd-t-5 pd-b-5 mg-r-20">移动</div>
              <div @click="onBatchChangeFlagClick" class="cursor-pointer pd-t-5 pd-b-5 mg-r-20">标记</div>
              <div @click="onBatchChangeModelClick" class="cursor-pointer pd-t-5 pd-b-5">更换模版</div>
            </div>
          </div>
        </div>
        <div id="div_cont">
          <div class="justify-content-space-between flex-wrap">
            <div v-bind:key="card.id" v-for="(card,index) in cardList" class="w-325 tooltip">
              <div
                  class="position-relative mg-t-15 border-1 bdc-f3 bdr-10 pd-12 fs-14 cursor-pointer column justify-content-space-between"
                  :style="'min-height: '+(showContent || showImg ?165:100)+'px'"
                  @click="onPreviewCardClick($event,card,index)">
                <div v-if="batchEditing" class="pd-t-4 pd-b-4 pd-r-5 sfld text-ellipsis-1">
                  <el-checkbox v-model="card.isChecked" class="c-9 " style="font-weight: normal"
                               @change="onCardSelectChange(card)"
                               @click.native="stopDefault($event)"
                  >
                    <div class="display-flex text-strong c-1 w-280"
                         :class="showContent?'text-ellipsis-2 h-44':'text-ellipsis-3 h-66'">
                      {{ formatSfld(card.sfld) }}
                    </div>
                  </el-checkbox>
                </div>
                <div v-if="!batchEditing" class="position-absolute t-0 r-5 fs-16 c-app-green tooltiptext"
                     @click.stop="onEditCardClick($event,card,index)">
                  <i class="el-icon-edit sfld" title="编辑"></i>
                </div>
                <div>
                  <div class="lh-22 c-2 fs-15" :class="batchEditing?'pd-l-25':''"
                       style="word-break:break-all;">
                    <div v-if="!batchEditing" class="cursor-pointer w-280 mg-b-5 text-strong"
                         :class="showContent?'text-ellipsis-2 h-44':'text-ellipsis-3 h-66'"
                         v-html="card.sfld"
                    >
                    </div>
                    <div v-if="showContent && card.fldsText" class="fs-13 c-9"
                         :class="showImg && card.img?'text-ellipsis-1':'text-ellipsis-4'"
                         v-html="card.fldsText"
                    >
                    </div>
                    <div v-if="showImg">
                      <div v-if="card.img" class="mg-t-5">
                        <el-popover trigger="hover" v-for="(img,i) in card.img">
                          <el-image :src="img" style="height: 300px;"></el-image>
                          <el-image
                              style="width:65px; height: 65px; margin-right: 5px;"
                              :src="img"
                              fit="contain"
                              slot="reference"
                          ></el-image>
                        </el-popover>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fs-12">
                  <div class="position-relative justify-content-space-between">
                    <div class="c-9 align-items-center mg-t-2">
                      <el-tooltip :visible-arrow="false" :content="card.deckName"
                                  placement="bottom-start">
                        <div class="c-9 fs-12 cursor-pointer text-ellipsis-1" style="max-width: 100px">
                          <span class="">{{ card.deckSimpleName }}</span>
                        </div>
                      </el-tooltip>
                    </div>
                    <div class="c-9 align-items-center">
                      <div class="mg-r-6">复习 <span class="c-2">{{ card.reps }}</span></div>
                      <div class="mg-r-6">忘记 <span class="c-2">{{ card.lapses }}</span></div>
                      <div class="mg-r-6" v-if="card.queue == -1">已暂停</div>
                      <div class="mg-r-6" v-else-if="card.queue == 0">新卡</div>
                      <div class="mg-r-6" v-else-if="card.queue == 1">学习中</div>
                      <div class="mg-r-6" v-else-if="card.afterDue !== undefined">{{
                          revTxt(card.afterDue)
                        }}
                      </div>
                      <div class="cursor-pointer mg-r-5">
                        <i @click.stop="onMarkedClick(card,0)" v-if="card.tags && card.tags.indexOf('marked') > 0"
                           class="el-icon-star-on fs-20 c-app-yellow"></i>
                        <i @click.stop="onMarkedClick(card,1)" v-else class="el-icon-star-on fs-20"></i>
                      </div>
                      <div @click.stop="onSelectFlagClick(index)" class="mg-r-5">
                        <i slot="reference" class="el-icon-s-flag fs-16 cursor-pointer"
                           :style="'color:'+util.getFlagColor(card.flags)"></i>
                      </div>
                      <div v-if="batchEditing" class="">
                        <i @click.stop="onDeleteCardClick(card)" class="el-icon-delete-solid fs-16"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pd-20" v-if="this.loadMoreIng">
          <div class="text-align-center c-8">加载中...</div>
        </div>
      </div>
    </div>
    <card-editor v-show="fullScreenEdit" :models="models" :decks="decks" :card="editCard"
                 v-on:close-click="onEditorCloseClick"
                 v-on:save-success="onSaveSuccess"
                 @toPreview="toPreview"
    ></card-editor>
    <!-- 预览的时候 -->
    <card-viewer ref="cardViewer" v-if="isPreview" :did="deck.id + ''"
                 @previewClose="previewClose"
                 @previewEditClick="previewEditClick"
                 :isPreview="isPreview"
                 :cardList="cardList"
                 :currentEditCard="currentEditCard"
                 :currentCardIndex="currentCardIndex"
    ></card-viewer>
    <!-- 开始学习的时候 -->
    <study-viewer ref="studyViewer" v-if="study" :did="deck.id + ''"
                  v-on:close-click="onStudyCompleted"
                  v-on:completed="onStudyCompleted"
                  v-on:edit-click="onEditCardClickFromViewer"
    >
    </study-viewer>
    <!--自定义学习-->
    <custom-study ref="customStudy" v-if="isCustomStudy" :did="deck.id + ''"
                  v-on:close-click="onStudyCompleted"
                  :isPreview="isCustomStudy"
                  :cardList="cardList"
                  :currentCardIndex="currentCardIndex"
    ></custom-study>
    <el-dialog :visible.sync="showSettingStudy" width="600px">
      <div slot="title">
        学习设置
      </div>
      <div v-if="settings" class="c-9" style="height: 500px;overflow-y: scroll">
        <!--<div>-->
        <div>
          <div class="c-1 text-strong">学习量</div>
          <div class="mg-t-10">每日新卡上限(张)</div>
          <el-input type="number" v-model="settings.newPerDay" class="mg-t-2"></el-input>
          <div class="mg-t-10">每日复习上限(张)</div>
          <el-input type="number" v-model="settings.revPerDay" class="mg-t-2"></el-input>
        </div>
        <div class="mg-t-20">
          <div class="c-1 text-strong">学习偏好</div>
          <div class="mg-t-10">新卡学习顺序</div>
          <el-select v-model="settings.newOrder" class="w-fill mg-t-2">
            <el-option label="按随机顺序" :value="0"></el-option>
            <el-option label="按添加的顺序" :value="1"></el-option>
          </el-select>
          <div class="mg-t-10">学习顺序</div>
          <el-select v-model="settings.newSpread" class="w-fill mg-t-2">
            <el-option label="按随机顺序" :value="0"></el-option>
            <el-option label="复习后再学习新卡" :value="1"></el-option>
            <el-option label="学习新卡牌后再复习" :value="2"></el-option>
          </el-select>
        </div>
        <div class="mg-t-20">
          <div class="c-1 text-strong">算法参数</div>
          <div class="mg-t-10">新卡间隔步伐(分)</div>
          <el-input v-model="settings.newDelays" class="mg-t-2"></el-input>
          <div class="mg-t-10">新卡毕业间隔(天)</div>
          <el-input v-model="settings.newGraduate" type="number" class="mg-t-2"></el-input>
          <div class="mg-t-10">新卡简单间隔(天)</div>
          <el-input v-model="settings.newFastGraduate" type="number" class="mg-t-2"></el-input>
          <div class="mg-t-10">新卡初始难度(%)</div>
          <el-input v-model="settings.initialFactor" type="number" class="mg-t-2"></el-input>
          <div class="mg-t-10">复习最大时间间隔(天)</div>
          <el-input v-model="settings.maxIvl" type="number" class="mg-t-2"></el-input>
          <div class="mg-t-10">复习简单奖励(%)</div>
          <el-input v-model="settings.ease4" type="number" class="mg-t-2"></el-input>
          <div class="mg-t-10">复习时间间隔因子(%)</div>
          <el-input v-model="settings.ivlFct" type="number" class="mg-t-2"></el-input>
          <div class="mg-t-10">失误间隔步伐(分)</div>
          <el-input v-model="settings.lapseDelays" type="number" class="mg-t-2"></el-input>
          <div class="mg-t-10">失误新的间隔(%)</div>
          <el-input v-model="settings.mult" class="mg-t-2"></el-input>
          <div class="mg-t-10">失误最小间隔(天)</div>
          <el-input v-model="settings.minInt" type="number" class="mg-t-2"></el-input>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showSettingStudy = false">关 闭</el-button>
        <el-button type="primary" @click="onSaveSettingStudyClick">保 存</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="showFlagOptions" width="400px" top="40vh">
      <div slot="title">标记</div>
      <div class="justify-content-center">
        <div @click.stop="onFlagClick(0)" class="pd-5">
          <i slot="reference" class="el-icon-s-flag fs-32 cursor-pointer c-9"></i>
        </div>
        <div @click.stop="onFlagClick(1)" class="pd-5">
          <i slot="reference" class="el-icon-s-flag fs-32 mg-l-25 cursor-pointer c-app-red "></i>
        </div>
        <div @click.stop="onFlagClick(2)" class="pd-5">
          <i slot="reference" class="el-icon-s-flag fs-32 mg-l-25 cursor-pointer c-app-yellow"></i>
        </div>
        <div @click.stop="onFlagClick(3)" class="pd-5">
          <i slot="reference" class="el-icon-s-flag fs-32 mg-l-25 cursor-pointer c-app-green"></i>
        </div>
        <div @click.stop="onFlagClick(4)" class="pd-5">
          <i slot="reference" class="el-icon-s-flag fs-32 mg-l-25 cursor-pointer c-app-blue"></i>
        </div>
      </div>
    </el-dialog>

    <export-card v-if="isExportCard" :exportDeck="exportDeck"
                 :isExportCard="isExportCard"
                 @closeExportCard="closeExportCard">
    </export-card>
    <select-drawer
        @changeDrawer="changeDrawer"
        @deckSelect="deckSelect"
        @moveHome="moveHome"
        :showDrawer="showDrawer"
        :showHome="true"
        title="选择记忆库"
    >
    </select-drawer>
    <el-drawer
        title="更换模板"
        :visible.sync="showSelectModelDrawer"
        :size="400"
        direction="ltr">
      <div class="pd-20">
        <div v-for="model in models" :key="model.id"
             class="align-items-center mg-b-20 pd-20 bg-f4f bdr-10 model-item cursor-pointer">
          <span class="flex-1 " @click="onModelSelect(model)"> {{ model.name }}
          <i v-if="model.usedCount !== undefined" class="fs-12 c-9">{{ model.usedCount }}卡牌</i></span>
        </div>
      </div>
    </el-drawer>
    <template>
      <el-backtop class="fs-14">顶部</el-backtop>
    </template>
  </div>
</template>

<script>
import DeckItem from '../components/DeckItem'
import CardViewer from '../components/CardViewer'
import StudyViewer from '../components/StudyViewer.vue'
import SelectDeck from '../components/SelectDeck'
import request from '../libs/api.request'
import util from '../libs/util'
import CardEditor from '../components/CardEditor'
import ExportCard from '../components/ExportCard.vue'

import SelectDrawer from '../components/select-drawer/SelectDrawer.vue'
import CustomStudy from "../components/CustomStudy";

export default {
  components: {
    CustomStudy,
    DeckItem,
    SelectDeck,
    CardViewer,
    CardEditor,
    ExportCard,
    SelectDrawer,
    StudyViewer,
  },
  data() {
    return {
      did: null,
      activeDid: null,
      deck: null,
      mainDeck: null,
      decks: {},
      allDecks: {},
      dconf: null,
      deckConf: null,
      cardListCount: 0,
      cardList: [],
      selectType: '',
      showFilter: false,
      showSelectModelDrawer: false,
      showSelectDeckDrawer: false,
      editCard: {},
      models: {},
      searchForm: {
        page: 1, size: 30, did: '', type: 'all', keyword: '', flag: 0,
        sort: parseInt(localStorage.getItem('cardListSort') || 1),
        sort_type: 'id',
        create_time: 365,
        answer_time: 30,
        next_reps_time: [0, 100],
        reps: [0, 20],
        lapses: [0, 20],
      },
      showImg: localStorage.getItem('showImg') == 1 ? true : false,
      showContent: localStorage.getItem('showContent') == 1 ? true : false,
      loadMoreIng: false,
      noMore: false,
      hasGetModels: false,
      study: false,
      fullScreenEdit: false,
      selectModel: null,
      currentEditorIndex: '',
      tpl: [],
      util: util,
      showSettingStudy: false,
      settings: null, //学习设置参数
      advanceDate: '', //提前日期，测试账号用的
      batchEditing: false,//批量选择中
      batchOptionIds: [],//批量选中的卡牌id,
      withoutBatchOptionIds: [],//批量选中时排除的卡牌,
      batchAllIsChecked: false,//是否全选
      isPreview: false, // 是否预览
      isCustomStudy: false,// 自定义学习
      currentCardIndex: 0,//当前点击的卡牌的索引
      editCardOrPrevCardIndex: '',// 记录进入编辑点击的卡牌的索引
      selectFlagCardIndex: -1,
      showFlagOptions: false,
      isExportCard: false,
      exportDeck: {},
      moveDeck: {},
      showDrawer: false,
      currentEditCard: {},
      // isSingleEditCard: false
      divSelTop: false,
      divDeskTop: false,
    }
  },
  methods: {
    onResetFilterClick() {
      this.searchForm.create_time = 365;
      this.searchForm.answer_time = 30;
      this.searchForm.next_reps_time = [0, 100];
      this.searchForm.reps = [0, 20];
      this.searchForm.lapses = [0, 20];
    },
    onFilterClick() {
      this.searchForm.page = 1;
      this.getCardList();
    },
    changeShowContent(val) {
      console.log(val);
      localStorage.setItem('showContent', val ? 1 : 0);
    },
    changeShowImg(val) {
      console.log(val);
      localStorage.setItem('showImg', val ? 1 : 0);
    },
    //移动到主页
    moveHome() {
      this.deckSelect(null)
    },
    // 选择要移动的记忆库
    deckSelect(deck) {
      if (this.selectType === 'moveCard') {
        if (deck == null) {
          return;
        }

        this.$confirm('已选卡牌将移动至记忆库：' + deck.simpleName + '，是否继续?', '移动卡牌', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'info',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              done();
              let loading = this.$loading('正在移动卡牌');
              request.request({
                url: '/space.card/batchMoveCard',
                method: 'post',
                data: {
                  checkedAll: this.batchAllIsChecked ? 'yes' : 'no',
                  batchOptionIds: JSON.stringify(this.batchOptionIds),
                  withoutBatchOptionIds: JSON.stringify(this.withoutBatchOptionIds),
                  searchForm: JSON.stringify(this.searchForm),
                  cardListCount: this.cardListCount,
                  did: deck.id,
                }
              }).then((data) => {
                loading.close();
                this.$message.success('操作成功');
                this.batchOptionIds = [];
                this.withoutBatchOptionIds = [];
                this.batchAllIsChecked = false;
                this.searchForm.page = 1;
                this.showDrawer = false;
                this.getCardList();
                this.getDeck();
              }).catch((e) => {
                loading.close();
                this.$message.error(e);
              });
            } else {
              done();
            }
          }
        })
      } else {
        let toDid
        if (deck === null) {
          toDid = 0
        } else {
          toDid = deck.id
        }
        request.request({
          url: '/space.deck/move',
          method: 'post',
          data: {
            did: this.moveDeck.id,
            toDid: toDid
          }
        }).then(res => {
          this.$message({
            type: 'success',
            message: '移动成功！'
          })
          setTimeout(() => {
            this.$router.go(0)
          }, 500);
        }).catch(err => {
          this.$message(err)
        })
      }
    },
    changeDrawer(value) {
      this.showDrawer = value
    },
    onSelectFlagClick(index) {
      this.showFlagOptions = true;
      this.selectFlagCardIndex = index;
    },
    stopDefault(e) {
      e.stopPropagation()
    },
    toPreview(card) {
      this.fullScreenEdit = false
      if (this.study) {
        return
      }
      if (card.nid === 0) {
        this.onPreviewCardClick(null, card)
        return
      }
      this.onPreviewCardClick(null, card, this.editCardOrPrevCardIndex)
    },
    previewClose() {
      this.isPreview = false
      this.currentEditCard = {}
    },
    // 预览下进到编辑
    previewEditClick(card) {
      // 如果是还未保存就预览的卡牌
      if (Object.keys(this.currentEditCard).length > 0) {
        this.isPreview = false
      }
      this.fullScreenEdit = true
      this.editCard = card
    },
    formatSfld(content) {
      if (content) {
        content = content.replace(/<img[^>]+>/g, '[图片]').replace(/<.*?>/g, "");
      }
      return content === '' ? '无内容' : content;
    },
    revTxt(due) {
      if (due > 0) {
        return due + '天后复习';
      } else if (due < 0) {
        return '逾期' + Math.abs(due) + '天';
      } else {
        return '今日复习';
      }
    },
    onBatchDeleteClick() {
      let count = 0;
      if (this.batchAllIsChecked) {
        if (this.withoutBatchOptionIds.length == this.cardListCount) {
          this.$message.info('请选择卡牌');
          return;
        }

        count = this.cardListCount - this.withoutBatchOptionIds.length;
      } else {
        if (this.batchOptionIds.length === 0) {
          this.$message.info('请选择卡牌');
          return;
        }

        count = this.batchOptionIds.length;
      }


      this.$confirm('所选' + count + '张卡牌将被删除，是否继续?', '删除卡牌', {
        confirmButtonText: '确认删除',
        cancelButtonText: '取消',
        type: 'error',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            done();
            let loading = this.$loading('正在删除卡牌');
            request.request({
              url: '/space.card/batchDeleteCard',
              method: 'post',
              data: {
                checkedAll: this.batchAllIsChecked ? 'yes' : 'no',
                batchOptionIds: JSON.stringify(this.batchOptionIds),
                withoutBatchOptionIds: JSON.stringify(this.withoutBatchOptionIds),
                searchForm: JSON.stringify(this.searchForm),
                cardListCount: this.cardListCount
              }
            }).then((data) => {
              loading.close();
              this.$message.success('已删除');
              this.searchForm.page = 1;
              this.getCardList();
            }).catch((e) => {
              loading.close();
              this.$message.error(e);
            });
          } else {
            done();
          }
        }
      })
    },
    onBatchSuspendClick(type) {
      if (type === -1) {
        type = '暂停';
      } else {
        type = '取消暂停';
      }
      let count = 0;
      if (this.batchAllIsChecked) {
        if (this.withoutBatchOptionIds.length == this.cardListCount) {
          this.$message.info('请选择卡牌');
          return;
        }

        count = this.cardListCount - this.withoutBatchOptionIds.length;
      } else {
        if (this.batchOptionIds.length === 0) {
          this.$message.info('请选择卡牌');
          return;
        }

        count = this.batchOptionIds.length;
      }


      this.$confirm('已选' + count + '张卡牌将被' + type + '，是否继续?', type + '卡牌', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            done();
            let loading = this.$loading('处理中');
            request.request({
              url: '/space.card/batchSuspendCard',
              method: 'post',
              data: {
                checkedAll: this.batchAllIsChecked ? 'yes' : 'no',
                batchOptionIds: JSON.stringify(this.batchOptionIds),
                withoutBatchOptionIds: JSON.stringify(this.withoutBatchOptionIds),
                searchForm: JSON.stringify(this.searchForm),
                cardListCount: this.cardListCount
              }
            }).then((data) => {
              loading.close();
              this.$message.success('操作成功');
              this.searchForm.page = 1;
              this.getCardList();
            }).catch((e) => {
              loading.close();
              this.$message.error(e);
            });
          } else {
            done();
          }
        }
      })

    },
    onBatchChangeFlagClick() {
      if (this.batchAllIsChecked) {
        if (this.withoutBatchOptionIds.length == this.cardListCount) {
          this.$message.info('请选择卡牌');
          return;
        }
      } else {
        if (this.batchOptionIds.length === 0) {
          this.$message.info('请选择卡牌');
          return;
        }
      }
      this.showFlagOptions = true;
    },
    onBatchMoveClick() {
      if (this.batchAllIsChecked) {
        if (this.withoutBatchOptionIds.length == this.cardListCount) {
          this.$message.info('请选择卡牌');
          return;
        }
      } else {
        if (this.batchOptionIds.length === 0) {
          this.$message.info('请选择卡牌');
          return;
        }
      }
      this.onSelectDeckClick('moveCard');
    },
    onBatchChangeModelClick() {
      this.selectType = 'forChangeModel';
      this.onSelectModelClick()
    },
    onSelectModelClick() {
      if (this.selectType === 'forChangeModel') {
        var count = this.batchAllIsChecked ? this.cardListCount - this.withoutBatchOptionIds.length : this.batchOptionIds.length;
        if (count <= 0) {
          this.$message.warning('请选择卡片')

        } else {
          this.getModels(() => {
            this.showSelectModelDrawer = true;
          })
        }
      }
    },
    onModelSelect(model) {
      if (this.selectType === 'forChangeModel') {
        let loading = this.$loading();
        this.$confirm('是否将选中的卡片更换模版？如所选卡片字段数与模版数不一致可能影响卡片的显示', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              request.request({
                url: '/space.card/batchChangeModel',
                method: 'post',
                data: {
                  checkedAll: this.batchAllIsChecked ? 'yes' : 'no',
                  batchOptionIds: JSON.stringify(this.batchOptionIds),
                  withoutBatchOptionIds: JSON.stringify(this.withoutBatchOptionIds),
                  searchForm: JSON.stringify(this.searchForm),
                  cardListCount: this.cardListCount,
                  modelId: model['id']
                }
              }).then((data) => {
                loading.close();
                this.showSelectModelDrawer = false;
                this.$message.success('操作成功');
                this.searchForm.page = 1;
                this.batchOptionIds = [];
                this.batchAllIsChecked = false;
                this.withoutBatchOptionIds = [];
                this.getCardList();
                this.selectType = '';
              }).catch((e) => {
                loading.close();
                this.$message.error(e);
              });
            }
            done();
          }
        });
      }
    },

    onSelectDeckClick(type) {
      this.selectType = type;
      this.showDrawer = true;
    },
    onSelectAllChange(e) {
      console.log(this.batchAllIsChecked);
      this.batchOptionIds = [];
      this.withoutBatchOptionIds = [];
      if (this.batchAllIsChecked) {
        this.cardList.forEach((v, k) => {
          this.cardList[k].isChecked = true;
        })
      } else {
        this.cardList.forEach((v, k) => {
          this.cardList[k].isChecked = false;
        })
      }
    },
    onCardSelectChange(card) {
      console.log(card.isChecked);
      if (card.isChecked) {
        if (this.withoutBatchOptionIds.length > 0) {
          let index = this.withoutBatchOptionIds.indexOf(card.id);
          if (index > -1) {
            this.withoutBatchOptionIds.splice(index, 1);
          }
        }

        if (!this.batchAllIsChecked) {
          this.batchOptionIds.push(card.id);
          if (this.batchOptionIds.length == this.cardListCount) {
            this.withoutBatchOptionIds = [];
          }
        }

        this.batchAllIsChecked = this.batchOptionIds.length == this.cardListCount;
      } else {
        if (this.batchOptionIds.length > 0) {
          let index = this.batchOptionIds.indexOf(card.id);
          if (index > -1) {
            this.batchOptionIds.splice(index, 1);
          }
        }

        if (this.batchAllIsChecked) {
          this.withoutBatchOptionIds.push(card.id);
          if (this.withoutBatchOptionIds.length == this.cardListCount) {
            this.batchAllIsChecked = false;
            this.batchOptionIds = [];
            this.withoutBatchOptionIds = [];
          }
        }
      }

      console.log("batchAllIsChecked", this.batchAllIsChecked);
      console.log("batchOptionIds", this.batchOptionIds);
      console.log("withoutBatchOptionIds", this.withoutBatchOptionIds);
    },
    onDeckSelect(deck) {

    },
    onBatchEditClick() {
      if (this.cardListCount === 0)
        return;
      this.batchAllIsChecked = false;
      this.batchOptionIds = [];
      this.withoutBatchOptionIds = [];
      this.batchEditing = !this.batchEditing;
    },
    onSwitchStudyClick() {
      this.$confirm(
          this.deck.enableStudy ?
              '是否要停止学习此记忆库？' : '是否要恢复学习此记忆库(新卡和复习量恢复为默认)？', '提示', {
            confirmButtonText: '是',
            cancelButtonText: '否',
            type: 'warning',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                let loading = this.$loading();
                request.request({
                  url: '/space.deck/switchStudy',
                  method: 'post',
                  data: {did: this.deck.id}
                }).then(() => {
                  loading.close();
                  done();
                  location.reload();
                }).catch((e) => {
                  done();
                  loading.close();
                  this.$message.error(e.message);
                });
              } else {
                done();
              }
            }
          });
    },
    onRestartStudyClick() {
      this.$confirm('是否要重置此卡牌组学习进度？', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            let loading = this.$loading();
            request.request({url: '/space.deck/restartStudy', method: 'post', data: {did: this.deck.id}}).then(() => {
              loading.close();
              done();
              location.reload();
            }).catch((e) => {
              done();
              loading.close();
              this.$message.error(e.message);
            });
          } else {
            done();
          }
        }
      });
    },
    onSelectSortTypeClick(sortType) {
      if (sortType === this.searchForm.sortType) {
        return;
      }
      this.searchForm.page = 1
      this.searchForm.sort_type = sortType;
      this.getCardList();
    },
    colorChange(color) {
      console.log(color);
      this.searchForm.flag = color;
      this.getCardList();
    },
    onBeginStudyClick() {
      sessionStorage.removeItem('result-time');
      sessionStorage.removeItem('question-statistics');
      this.study = true;
    },
    onSettingStudyClick() {
      this.showSettingStudy = true;
    },
    onSaveSettingStudyClick() {
      let loading = this.$loading();
      request.request({url: '/space.deck/saveSetting', method: 'post', data: this.settings}).then(() => {
        loading.close();
        this.getDeck();
        this.showSettingStudy = false;
      }).catch((e) => {
        loading.close();
        this.$message.error(e.message);
      });
    },
    onStudyCompleted() {
      this.study = false;
      this.isCustomStudy = false;
      this.currentEditCard = {}
      if (this.activeDid != this.did) {
        this.getChildDeck();
      } else {
        this.getDeck();
      }
      this.searchForm.page = 1;
      this.getCardList();
    },
    onFlagClick(flags) {
      if (this.selectFlagCardIndex === -1) {
        let loading = this.$loading('处理中');
        request.request({
          url: '/space.card/batchFlagCard',
          method: 'post',
          data: {
            checkedAll: this.batchAllIsChecked ? 'yes' : 'no',
            batchOptionIds: JSON.stringify(this.batchOptionIds),
            withoutBatchOptionIds: JSON.stringify(this.withoutBatchOptionIds),
            searchForm: JSON.stringify(this.searchForm),
            cardListCount: this.cardListCount,
            flag: flags
          }
        }).then(() => {
          loading.close();
          this.showFlagOptions = false;
          this.$message.success('操作成功');
          this.searchForm.page = 1;
          this.getCardList();
        }).catch((e) => {
          loading.close();
          this.$message.error(e);
        });
      } else {
        let card = this.cardList[this.selectFlagCardIndex];
        let oldFlags = card.flags;
        card.flags = flags;
        request.request({url: '/space.card/setFlags', method: 'post', data: {cardId: card.id, flags: flags}})
            .then((data) => {
              this.showFlagOptions = false;
            }).catch((e) => {
          this.$message.error(e);
          this.showFlagOptions = false;
          card.flags = oldFlags;
          this.selectFlagCardIndex = -1;
        }).catch(() => {
          this.selectFlagCardIndex = -1;
        })
      }
    },
    onDeleteCardClick(card) {
      this.$confirm('是否删除该卡牌?', '提示', {
        confirmButtonText: '确定',
        confirmButtonClass: 'bg-app-red bdc-app-red',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            let loading = this.$loading();
            request.request({
              url: '/space.card/deleteCard',
              method: 'post',
              data: {id: card.id,}
            }).then((data) => {
              done();
              loading.close();
              this.$message.success('已删除');
              this.searchForm.page = 1;
              this.withoutBatchOptionIds = [];
              this.batchOptionIds = [];
              this.getCardList();
            }).catch((e) => {
              done();
              loading.close();
              this.$message.error(e);
            })
          } else {
            done();
          }
        }
      })
    },
    onDeckClick(deck) {
      this.activeDid = deck.id + '';
      this.getChildDeck();
    },
    onMarkedClick(card, type) {
      card.visibleFlags = false;
      let oldTags = card.tags;
      if (type) {
        card.tags += ' marked ';
        card.tags = card.tags.replace('  ', ' ');
      } else {
        card.tags = ' ' + card.tags.replace(' marked ', ' ').trim() + ' ';
      }

      request.request({url: '/space.card/setMarked', method: 'post', data: {noteId: card.nid, type: type}})
          .then((data) => {

          }).catch((e) => {
        this.$message.error(e);
        card.tags = oldTags;
      })
    },
    onBuyCardClick() {
      window.open("https://file.ankichinas.cn/card/" + this.deck.cardId + '?app-token=' + this.$parent.authToken);
    },
    onAddCardClick(deck) {
      this.getModels(() => {
        console.log('>>>>>>>');
        console.log(this.models);
        let model = this.models[Object.keys(this.models)[0]];
        console.log('>>>>>>>');
        if (this.deck.modelUsed && this.deck.modelUsed.length > 0) {
          model = this.models[this.deck.modelUsed[0].mid];
        }

        console.log('>>>>>');
        console.log('>>>>>');
        console.log('>>>>>');
        this.editCard = {nid: 0, mid: model.id, did: deck.id, flds: []};
        this.fullScreenEdit = true;
      });
    },
    onSaveSuccess(flds) {
      this.currentEditCard = {}
      if (this.editCard.nid) {
        this.$message.success('已保存');
        this.editCard.sfld = flds[0];
        this.editCard.flds = flds;
        if (this.study) {
          this.$refs.studyViewer.setCardFlds(flds);
          this.fullScreenEdit = false;
        }
      } else {
        this.$message.success('卡牌添加成功');
        if (this.activeDid != this.did) {
          this.getChildDeck(true);
        } else {
          this.getDeck(true);
        }
        this.searchForm.page = 1;
        this.getCardList();
      }
    },
    onEditorCloseClick(e) {
      try {
        if (e && e.type == 'delete') {
          let index = -1;
          this.cardList.forEach((v, k) => {
            if (v.id == e.id) {
              index = k;
            }
          });
          if (index > -1) {
            this.cardList.splice(index, 1);
            this.cardListCount--;
          }
        }
      } catch (e) {
      }
      this.editCard = {};
      this.currentEditCard = {}
      this.fullScreenEdit = false;
    },
    // 进入card预览
    onPreviewCardClick(e, card, index) {
      this.currentCardIndex = index
      this.isPreview = true
      if (card.nid === 0 && card.flds[0] !== '') {
        this.currentEditCard = card
      }
      this.onEditCardClick(null, card)
    },
    // 进入card自定义学习
    onCustomStudyClick() {
      if (!this.$parent.loginUser.vip || (this.$parent.loginUser.vip && !this.$parent.loginUser.vip.vipAvailable)) {
        let key = 'customStudy';
        let useCount = localStorage.getItem(key) || 0;
        useCount++;
        localStorage.setItem(key, useCount);
        let customStudy = this.$parent.loginUser.nonmember.customStudy;
        if (customStudy && customStudy >= useCount) {
          this.$confirm('自定义学习为VIP超级学霸功能，开通会员后无限制使用🌹', '提示', {
            confirmButtonText: '先试用一下',
            cancelButtonText: '立即开通',
            showClose: false,
            closeOnClickModal: false,
          }).then(() => {

          }).catch(() => {
            this.$parent.onVipShowClick();
          });
        } else {
          this.$alert('自定义学习VIP超级学霸功能已到达上限，需开通会员才可使用哦🌹', '提示', {
            confirmButtonText: '确定',
            callback: () => {
              this.$parent.onVipShowClick();
            }
          });
          return;
        }
      }
      this.isCustomStudy = true
      this.currentEditCard = this.cardList[0];
    },
    // 进入card编辑
    onEditCardClick(e, card, index) {
      this.editCardOrPrevCardIndex = index
      if (!this.mainDeck) {
        this.$message.warning('正在获取卡牌数据请稍候');
      } else {
        this.getModels(() => {
          let findDeck = this.getDeckByDid(this.decks, card.did);
          if (findDeck == null) {
            this.$message.warning('数据有误');
            return;
          }
          this.checkCardFlds(card, () => {
            if (this.isPreview) {
            } else {
              this.editCard = card;
              this.fullScreenEdit = true;
            }
          });
        });
      }
    },
    checkCardFlds(card, callback) {
      if (card.flds === false) {
        let loading = this.$loading({text: '获取卡牌内容'});
        request.request({
          url: '/space.card/getFlds',
          params: {noteId: card.nid}
        }).then(data => {
          loading.close();
          card.flds = data.flds;
          callback();
        }).catch(e => {
          loading.close();
          this.$message.error(e);
        })
      } else {
        callback();
      }
    },
    //学习到编辑
    onEditCardClickFromViewer(card) {
      this.onEditCardClick(null, card);
    },
    onAdvanceDateClick() {
      this.$prompt('', '增加或减少天数', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '输入数字',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm' && instance.inputValue) {
            let loading = this.$loading();
            request.request({
              url: '/space.index/updateAdvanceDate',
              method: 'post',
              data: {
                date: instance.inputValue
              }
            }).then((data) => {
              done();
              setTimeout(() => {
                location.reload();
              }, 200)
            }).catch((e) => {
              loading.close();
              this.$message.error(e);
            })
          } else {
            done();
          }
        }
      });
    },
    getDeckByDid(decks, did) {
      let deck = null;
      for (let k in decks) {
        if (decks[k].id == did) {
          return decks[k];
        }
        if (decks[k].decks) {
          deck = this.getDeckByDid(decks[k].decks, did);
          if (deck) {
            break;
          }
        }
      }
      return deck;
    },
    getModels(callback) {
      if (this.hasGetModels) {
        callback();
      } else {
        let loading = this.$loading();
        request.request({
          url: '/space.index/models',
        }).then(data => {
          this.hasGetModels = true;
          loading.close();
          if (data.models) {
            this.models = Object.assign(data.models, this.models);
          }
          callback();
        }).catch(e => {
          loading.close();
          this.$message.error(e);
        })
      }
    },
    onSelectTypeClick(type) {
      if (type === this.searchForm.type) {
        return;
      }
      this.searchForm.page = 1;
      this.searchForm.type = type;
      this.getCardList();
    },
    onSearchCard() {
      this.searchForm.page = 1;
      this.getCardList();
    },
    onSortClick() {
      this.searchForm.sort = this.searchForm.sort === 1 ? 2 : 1;
      localStorage.setItem('cardListSort', this.searchForm.sort);
      this.onSearchCard();
    },
    getCardList() {
      request.request({
        url: '/space.card/cardList', params: Object.assign(this.searchForm, {})
      }).then((data) => {
        this.loadMoreIng = false;
        data.list.forEach((v, k) => {
          data.list[k].isChecked = this.batchAllIsChecked;
          //data.list[k].img = ['/static/image/test.jpg'];
          console.log(data.list[k].isChecked);
          if (this.searchForm.keyword !== '') {
            let text = "<span class='text-strong c-app-green'>" + this.searchForm.keyword + "</span>";
            data.list[k].fldsText = data.list[k].fldsText.replace(this.searchForm.keyword, text);
            data.list[k].sfld = data.list[k].sfld.replace(this.searchForm.keyword, text);
          }
        });
        if (this.searchForm.page === 1) {
          this.cardList = data.list;
          this.cardListCount = data.count;
        } else {
          this.cardList = this.cardList.concat(data.list);
        }
        this.noMore = data.list.length < this.searchForm.size;
      }).catch((e) => {
        this.loadMoreIng = false;
        this.$message.error(e);
      })
    },
    loadMoreCard() {
      if (this.loadMoreIng || this.noMore) {
        return;
      }
      this.loadMoreIng = true;
      this.searchForm.page++;
      this.getCardList();
    },
    handleScroll() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop; //变量windowHeight是可视区的高度
      if (scrollTop + document.body.clientHeight > document.body.scrollHeight - 50) {
        console.log("loadMoreCard");
        this.loadMoreCard();
      }
    },
    onAddDeckClick(deck) {
      let desc = '位置：' + deck.name.replace(/::/g, " > ");
      this.$prompt(desc, '创建子记忆库', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '请输入创建的记忆库名称',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm' && instance.inputValue) {
            let loading = this.$loading();
            request.request({
              url: '/space.deck/createDeck',
              method: 'post',
              data: {
                did: deck.id,
                name: instance.inputValue
              }
            }).then((data) => {
              done();
              setTimeout(() => {
                loading.close();
                this.$message.success('操作成功');
                this.getDeck();

              }, 200)
            }).catch((e) => {
              loading.close();
              this.$message.error(e);
            })
          } else {
            done();
          }
        }
      });
    },
    onEditDeckClick(deck) {
      this.$prompt('', '重命名', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: deck.simpleName,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm' && instance.inputValue && instance.inputValue !== deck.name) {
            let loading = this.$loading();
            request.request({
              url: '/space.deck/editDeck',
              method: 'post',
              data: {
                did: deck.id,
                parentId: deck.parentId,
                name: instance.inputValue
              }
            }).then((data) => {
              done();
              setTimeout(() => {
                loading.close();
                this.$message.success('操作成功');
                this.getDeck();
              }, 200)
            }).catch((e) => {
              loading.close();
              this.$message.error(e);
            })
          } else {
            done();
          }
        }
      });
    },
    onDeleteDeckClick(deck) {
      this.$confirm('是否删除【' + deck.simpleName + '】?（内含卡牌也会被删除）', '提示', {
        confirmButtonText: '确定',
        confirmButtonClass: 'bg-app-red bdc-app-red',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            let loading = this.$loading();
            request.request({
              url: '/space.deck/deleteDeck',
              method: 'post',
              data: {did: deck.id}
            }).then((data) => {
              loading.close();
              done();
              this.$message.success('已删除');
              if (deck.id === this.did) {
                setTimeout(() => {
                  this.$router.replace({path: '/'});
                }, 800)
              } else {
                this.getDeck();
              }
            }).catch((e) => {
              loading.close();
              this.$message.error(e);
            })
            done();
          } else {
            done();
          }
        }
      })
    },
    onCheckDeckDataClick(deck) {
      this.$confirm('检查整理数据', '提示', {
        confirmButtonText: '好的',
        confirmButtonClass: 'bg-app-red bdc-app-red',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            let loading = this.$loading();
            request.request({
              url: '/space.deck/checkDeckData',
              method: 'post',
              data: {did: deck.id}
            }).then((data) => {
              this.$message.success('完成');
              setTimeout(() => {
                location.reload();
              }, 700);
            }).catch((e) => {
              loading.close();
              this.$message.error(e);
            })
            done();
          } else {
            done();
          }
        }
      })
    },
    getChildDeck(disableLoading) {
      let loading = null;
      if (!disableLoading) {
        loading = this.$loading();
      }
      request.request({
        url: '/space.deck/getChildDeckById',
        params: {did: this.activeDid}
      }).then(data => {
        loading && loading.close();
        this.deck = data.deck;
        this.searchForm.did = this.deck.id;
        this.searchForm.page = 1;
        this.getCardList();
      }).catch(e => {
        loading && loading.close();
      });
    },
    getDeck(disableLoading) {
      let loading = null;
      if (!disableLoading) {
        loading = this.$loading();
      }
      request.request({
        url: '/space.deck/getDeckById',
        params: {did: this.did}
      }).then(data => {
        if (loading)
          loading.close();
        this.mainDeck = data.deck;
        this.allDecks = data.allDecks;
        this.$store.commit('setDecksData', this.allDecks);
        this.decks[this.mainDeck.name] = this.mainDeck;
        this.deck = data.deck;
        this.dconf = data.dconf;
        this.advanceDate = data.advanceDate;
        this.deckConf = this.dconf[this.mainDeck.conf];
        this.settings = {
          conf: this.mainDeck.conf,
          newPerDay: this.deckConf.new.perDay,
          revPerDay: this.deckConf.rev.perDay,
          newOrder: this.deckConf.new.order,
          newSpread: data.col.conf.newSpread,
          newDelays: this.deckConf.new.delays.join(" "),
          newGraduate: this.deckConf.new.ints[0],
          newFastGraduate: this.deckConf.new.ints[1],
          initialFactor: this.deckConf.new.initialFactor / 10,
          maxIvl: this.deckConf.rev.maxIvl,
          ease4: this.deckConf.rev.ease4 * 100,
          ivlFct: this.deckConf.rev.ivlFct * 100,
          lapseDelays: this.deckConf.lapse.delays.join(" "),
          minInt: this.deckConf.lapse.minInt,
          mult: Math.ceil(this.deckConf.lapse.mult * 100),
        }
      }).catch((e) => {
        loading.close();
        if (e.code == 404) {
          this.$router.replace({path: '/'});
          return;
        }
        this.$message.error(e);
      })
    },
    closeExportCard() {
      this.isExportCard = false
    },
    onOptionsClick(event) {
      if (event.type === 'addDeck') {
        this.onAddDeckClick(event.deck);
      } else if (event.type === 'editDeck') {
        this.onEditDeckClick(event.deck);
      } else if (event.type === 'deleteDeck') {
        this.onDeleteDeckClick(event.deck);
      } else if (event.type === 'addCard') {
        this.onAddCardClick(event.deck);
      } else if (event.type === 'exportDeck') {
        this.exportDeck = event.deck
        this.isExportCard = true
      } else if (event.type === 'moveDeck') {
        this.showDrawer = true
        this.moveDeck = event.deck
        console.log('移动卡牌', event.deck);
      }
    },
    divSelElementOutOfViewport() {
      const devSel = document.getElementById('div_sel');
      const rect = devSel.getBoundingClientRect();
      let ret = (
          rect.top > window.innerHeight || // 元素底部完全在视口上方
          rect.bottom < 60 || // 元素顶部完全在视口下方60
          rect.left > window.innerWidth || // 元素右侧完全在视口左侧
          rect.right < 0 // 元素左侧完全在视口右侧
      );
      if (ret) {
        if (!this.divSelTop) {
          this.divSelTop = true;
          this.divDeskTop = true;
        }
      }
    },
    divContElementOutOfViewport() {
      const devCont = document.getElementById('div_cont');
      const rect = devCont.getBoundingClientRect();
      let ret = (
          rect.top < 60 // 元素底部完全在视口上方
      );
      if (!ret) {
        if (this.divSelTop) {
          this.divSelTop = false;
        }
        if (this.divDeskTop) {
          this.divDeskTop = false;
        }
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.divSelElementOutOfViewport, true);
    window.addEventListener('scroll', this.divContElementOutOfViewport, true);

    this.$parent.showImportOption = false;
    window.scrollTo(0, 0);
    let paramDid = this.$route.params.did;
    if (paramDid.indexOf('-') > -1) {
      paramDid = paramDid.split('-');
      this.did = paramDid[0];
      this.activeDid = paramDid[1];
      this.searchForm.did = paramDid[1];
    } else {
      this.did = paramDid;
      this.activeDid = this.did;
      this.searchForm.did = this.did;
    }
    if (!this.did) {
      this.$router.replace({path: '/'})
    }

    this.getDeck();
    this.getCardList();
    window.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  watch: {
    fullScreenEdit() {
      this.currentEditorIndex = '';
      if (this.fullScreenEdit) {
        document.body.style.cssText = 'overflow-y: hidden; height: 100%;';
      } else {
        document.body.style.cssText = 'overflow-y: auto; height: 100%;'
      }
    },
    currentEditCard(n, o) {
      console.log(111);
      console.log(n);
    }
  },
}
</script>

<style>

.tabs div {
  border-bottom: 2px solid transparent;
  color: #999999;
  cursor: pointer;
}

.tabs div.active {
  /*border-color: #222222;*/
  color: #222222;
  font-weight: 600;
}

.container /deep/ .el-input__inner {
  background-color: #f3f3f3;
  border: none;
  border-radius: 20px;
}

.sfld:hover {
  color: #28BEA0;
}

.cancelBtn >>> .el-button {
  background-color: #28BEA0;
  color: #fff;
  width: 200px;
  border-radius: 10px;
  margin: 20px 0;
}

.continueBtn >>> .el-button {
  background-color: #ff9041;
  color: #fff;
  width: 200px;
  border-radius: 10px;
  margin: 20px 0;
}

.myLoading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.myLoading .text {
  margin-top: 20px;
  font-size: 20px;
  color: #28BEA0;
}

.my-loading-icon {
  font-size: 80px;
  color: #28BEA0;
}

.el-button--warning {
  color: #fff;
  background-color: #FFB200;
  border-color: #FFB200;
}

.tooltip {

}

.tooltip .tooltiptext {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #999999 !important;
}
</style>
